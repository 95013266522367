import { useQueries } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { axiosClient, backendUrl, useAxios } from '../utils';
import { TwoBarContentLoader } from '../component/contentLoading';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';

import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
//import axios from 'axios'
import { CustomFormSelect, FormInput, FormSelect, FormSelectImage } from '../component/form';
import { AddNumber, SnackBarStatus } from '../component';
import { DialogComponent } from '../component/dialog';

export const DepotTransaction = () => {
  const axiosInstance = useAxios()

    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['category'],
          queryFn: () => axiosClient.get('category'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        {
          queryKey: ['apiAccountNumber'],
          queryFn: () => axiosInstance.get('/api/accountNumber'),
        },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [wallets,categories,paymentMethod,accountNumber] = results
    
    
    return(
      <>
      {(
         categories.isLoading 
        || categories.data.data === undefined 
        || wallets.isLoading
        || wallets.data.data === undefined
        || paymentMethod.isLoading
        || paymentMethod.data.data === undefined
        || accountNumber.isLoading
        || accountNumber.data.data === undefined
        ) && <TwoBarContentLoader/>}
    
      {categories.isSuccess 
        && categories.data.data !== undefined  
        && wallets.isSuccess
        && wallets.data.data !== undefined
        && paymentMethod.isSuccess
        && paymentMethod.data.data !== undefined
        && accountNumber.isSuccess
        && accountNumber.data.data !== undefined
        && 
      <DepotTransactionLayout 
        wallets={wallets.data.data} 
        categories={categories.data.data}
        paymentMethodData={paymentMethod.data.data}
        accountNumber={accountNumber.data.data}
        />
      }
      </>
  )
}

const DepotTransactionLayout = ({categories, wallets, paymentMethodData, accountNumber}) => {
        
    const { id } = useParams()
   
   
   const axiosInstance = useAxios()

  const [isLoading, setIsLoading]= useState(false)
  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)

   // console.log("acc",accountNumber)
    const navigate = useNavigate()
    //const [wallet,setWallet] = useState(wallets.find(w => w.id === id))
    const wallet = wallets.find(w => w.id == id)
    //const category = categories.find(c => c.id == wallet.categoryId)
    const filteredPm = paymentMethodData.filter(payment => wallet.paymentMethod.includes(payment.id));

    const [regexPaymentMethod, setRegexPaymentMethod] = useState('feno')
    const [notationFormat,setNotationFormat] = useState('feno')

    const dataForm = new FormData()

    /*
    categoryId =  1 // fiat
    categoryId =  2// national
    categoryId = 3// crypto
    categoryId =  // 
    
    */

    const vSchema = () => {
      if (wallet.categoryId === 2){
        return Yup.object().shape({
          balanceRequested : Yup.number()
              .typeError("Veuillez entrer un chiffre")
              .required("Veillez ajouter un nombre"),
          accountNumber: Yup.string()
          .required("Veillez entrer un numero de compte"),
          //.matches(new RegExp(regexPaymentMethod),`Veuillez entrer ${notationFormat} valide`),
          paymentMethod: Yup.string().required('Payment method is required'),
        })
      } else {
        return Yup.object().shape({
          balanceRequested : Yup.number()
              .typeError("Veuillez entrer un chiffre")
              .required("Veillez ajouter un nombre"),
          //.matches(new RegExp(regexPaymentMethod),`Veuillez entrer ${notationFormat} valide`),
          paymentMethod: Yup.string().required('Payment method is required'),
        })
      }
    }

    const validationSchema =  vSchema()

    console.log('valide',validationSchema)
  
    const methods = useForm({
        resolver:yupResolver(validationSchema),
        defaultValues: {
            paymentMethod: undefined,
          },
      })
  
      
    const {handleSubmit,watch,formState: { errors }} = methods
  
    const selectedPaymentMethod = watch('paymentMethod')
    const [pm,setPM] = useState({})
    const [currentAcountNumber,setCurrentAcountNumber] = useState([])

    useEffect(()=>{
      console.log('s',selectedPaymentMethod)
      if(selectedPaymentMethod !== undefined){
        const pm = filteredPm.find(w => w.id == selectedPaymentMethod)
        const acn = accountNumber.filter(a=>a.paymentMethodId===selectedPaymentMethod)
        //console.log(pm)
        setPM(pm)
        setCurrentAcountNumber(acn)
        setRegexPaymentMethod(pm.format)
        setNotationFormat(pm.notationFormat)
      }
    },[selectedPaymentMethod])

      const onSubmit = (data) => {
        setIsLoading(true)
       //console.log(data)
  
      console.log(data)
       //alert('')
      dataForm.append('paymentMethod',data.paymentMethod)
      dataForm.append('category',wallet.categoryId )
      dataForm.append('accountNumber',data.accountNumber)
      dataForm.append('balanceRequested',data.balanceRequested)
      dataForm.append('type','depot')
      dataForm.append('walletId',wallet.id)
      
      //alert('mety')
      axiosInstance.post('/api/transaction',dataForm,
      {
          headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm => dataForm,//to solve axios issue
      })
      .then(resp => {
          setSnackbar({children:'Creation de nouveau mode de paiement avec success', severity: 'success'})
          setIsLoading(false)
          setStatus(true)
          navigate('/transac/comfirmation/'+resp.data.transactionId)
      })
      .catch(error => {
          setIsLoading(false)
          setSnackbar({children:'Erreur sur la creation de nouveau mode de paiement', severity: 'error'})
          setStatus(true)
          //console.log(error.response.data.message)
          })
      }
  
      // all form control for dialogue add number
      const [addNumberOpened, setAddNumberOpened] = useState(false) 
      const [warning,setWarning] = useState(false)
      const handleOpenAddNumber = () => {
          //console.log(pm)
          if(selectedPaymentMethod===undefined){
            setWarning(true)
          }
          else {
            setAddNumberOpened(true)
          }
      }


    return (
      <Box mt={3}>
        {/* Category National */}
        <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
        <DialogComponent open={warning} setOpen={setWarning}
          action={<>
            <Button variant='contained' onClick={()=>setWarning(false)}>OK</Button>
          </>}
        >
          <Typography>Selectioner un method de payment d'abord</Typography>
        </DialogComponent>
        <AddNumber 
          open={addNumberOpened} 
          setOpen={setAddNumberOpened} 
          regex={regexPaymentMethod} 
          notation={notationFormat} 
          id={pm.id} 
          title={pm.name}
          postAction={()=>alert('done')}
          />

        { wallet.categoryId === 2 &&
         <FormProvider {...methods}>
          <form>
            <Stack spacing={1}>
              <Box sx={{
                display:'flex',
                //justifyContent:'space-between'
              }}>
                <img src={backendUrl+'image/logo/'+wallet.icon} width={40} height={40}/>
                <Box sx={{ml:2}}>
                  <Typography sx={{
                    fontWeight:'bold'
                  }}>
                    {wallet.symbol}
                  </Typography>
                  <Typography sx={{color:'grey', fontStyle:'italic'}}>
                  {wallet.name}
                  </Typography>
                </Box>
              </Box>
              <Typography variant='h6' sx={{fontWeight:'bold'}}></Typography>
              <FormSelectImage name='paymentMethod' label={'Quel est votre mode de payement?'} options={filteredPm} error={!!errors?.paymentMethod} helpertext={errors?.paymentMethod?.message} fullWidth/>
            
              
              {/**************************************************** */}
              <FormInput name='balanceRequested' label={`Combien de ${wallet.symbol} souhaitez vous deposer?`} error={!!errors?.balanceRequested} helperText={errors?.balanceRequested?.message} fullWidth/>
              
              
              <CustomFormSelect name='accountNumber' label="Quel est votre numero de compte?" error={!!errors?.accountNumber} helperText={errors?.accountNumber?.message} >
                {currentAcountNumber.map((item,index)=>(
                    <MenuItem key={index} value={item.id}> {item.accountNumber}     ({item.name})</MenuItem>
                ))}
              </CustomFormSelect>
              <Box sx={{pb:2, display:'flex', alignItems:'center'}}>
                <Typography>Trouvez-vous votre numéro?</Typography>
                <Button onClick={handleOpenAddNumber}>Ajouter le ici</Button>
              </Box>
            {/***************************************************** */}

            <Button variant='contained' onClick={handleSubmit(onSubmit)}>
              { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Contunier"}
            </Button>
            </Stack>
          </form>
          </FormProvider>
        }
        {/* END Category National */}

        {/* END Category Fiat (USD) */}
        {
          wallet.categoryId === 1 &&
          <FormProvider {...methods}>
            <form>
          <Stack spacing={1}>
            <Box sx={{
              display:'flex',
              
              //justifyContent:'space-between'
            }}>
              <img src={backendUrl+'image/logo/'+wallet.icon} width={40} height={40}/>
              <Box sx={{ml:2}}>
                <Typography sx={{
                  fontWeight:'bold'
                }}>
                  {wallet.symbol}
                </Typography>
                <Typography sx={{color:'grey', fontStyle:'italic'}}>
                {wallet.name}
                </Typography>
              </Box>
            </Box>
            <Typography variant='h6' sx={{fontWeight:'bold'}}></Typography>
            <FormSelectImage name='paymentMethod' label={'Quel est votre choix du Reseau?'} options={filteredPm} error={!!errors?.paymentMethod} helpertext={errors?.paymentMethod?.message} fullWidth/>


            {/**************************************************** */}
            <TextField label="Combien d'USD souhaitez vous deposer?" fullWidth/>

            
          {/***************************************************** */}
          <Button variant='contained' onClick={handleSubmit(onSubmit)}>
              { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Contunier"}
            </Button>
          </Stack>
          </form>
          </FormProvider>
        }

        {/* END Fiat */}

        {/* crypto */}
        {
          wallet.categoryId === 3 &&
          <FormProvider {...methods}>
            <form>
          <Stack spacing={1}>
            <Box sx={{
              display:'flex',
              
              //justifyContent:'space-between'
            }}>
              <img src={backendUrl+'image/logo/'+wallet.icon} width={30} height={30}/>
              <Box sx={{ml:2}}>
                <Typography sx={{
                  fontWeight:'bold'
                }}>
                  {wallet.symbol}
                </Typography>
                <Typography sx={{color:'grey', fontStyle:'italic'}}>
                {wallet.name}
                </Typography>
              </Box>
            </Box>
            <Typography variant='h6' sx={{fontWeight:'bold'}}></Typography>

            <FormSelectImage name='paymentMethod' label={'Quel est votre choix du Reseau?'} options={filteredPm} error={!!errors?.paymentMethod} helpertext={errors?.paymentMethod?.message} fullWidth/>

            {/**************************************************** */}
            <FormInput name='balanceRequested' label={`Combien de ${wallet.symbol} souhaitez vous deposer?`} error={!!errors?.balanceRequested} helperText={errors?.balanceRequested?.message} fullWidth/>
            

          {/***************************************************** */}
          <Button variant='contained' onClick={handleSubmit(onSubmit)}>
              { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Contunier"}
            </Button>
          </Stack>
          </form>
          </FormProvider>
        }
        {/* END Crypto */}
      </Box>
    )
  }
