import React from "react";
import { Alert} from "@mui/material"

// Extend Alert to handle custom severity
export const CustomAlert = ({ severity, children, sx,props}) => {
  //const isGreyInfo = severity === "greyInfo";

  return (
    <Alert
      {...props}
      variant="filled"
      severity={severity} // Prevent invalid prop error
      sx={{
        bgcolor:"grey", // Custom grey background
        color: "black", // Text color
        ...sx,
      }}
    >
      {children}
    </Alert>
  )
}


