import { useQueries } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { TwoBarContentLoader } from '../component/contentLoading';
import { axiosClient, backendUrl, numberFormatter, useAxios } from '../utils';
import { useParams } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Container, Divider, Grid, IconButton, Paper, Stack, StepContent, Typography } from '@mui/material';
import { Copy, CopyToClipboard, CountdownComponent, CustomAlert, StatusIcon } from '../component/ui'
import { MdOutlineCall } from "react-icons/md"
import { ReadyState } from 'react-use-websocket'

import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import { MdCheckCircle, MdRadioButtonUnchecked } from 'react-icons/md';
import { Title } from '../component/admin';
import { TaskAlt } from '@mui/icons-material';
import axios from 'axios';
import { SnackBarStatus } from '../component';
import { DialogPersistedComponent } from '../component/dialog';
import { useWalletContext, useWebsocketContext } from '../context';


const steps = [
    { label: "Envoi d'argent",  description: 'alle' , error: "Echec sur l'envoie " },
    { label: 'Comfirmation du commande',  description: 'Comfirmation en cours. Le dépôt sera crédité après confirmation.', error: "Echec sur la comfirmation" },
    { label: 'Effectué',  description: 'alle', error: 'failed in this step' },
  ];


export const TransactionComfirmation = () => {
    const axiosInstance = useAxios()

    const { transactionId } = useParams()

    ///
    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        {
          queryKey: ['apiAccountNumber'],
          queryFn: () => axiosInstance.get('/api/accountNumber'),
        },
        {
            queryKey: ['transaction'+transactionId],
            queryFn: () => axiosInstance.get('api/transaction/'+transactionId),
          },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [wallets,paymentMethod,accountNumber,transaction] = results
    
    return(
      <>
      {(
          wallets.isLoading
        || wallets.data.data === undefined
        || paymentMethod.isLoading
        || paymentMethod.data.data === undefined
        || accountNumber.isLoading
        || accountNumber.data.data === undefined
        || transaction.isLoading
        || transaction.data.data === undefined
        ) && <TwoBarContentLoader/>}
    
      {  wallets.isSuccess
        && wallets.data.data !== undefined
        && paymentMethod.isSuccess
        && paymentMethod.data.data !== undefined
        && accountNumber.isSuccess
        && accountNumber.data.data !== undefined
        && transaction.isSuccess
        && transaction.data.data !== undefined
        && 
      <TransactionComfirmationLayout 
        wallets={wallets.data.data} 
        paymentMethodData={paymentMethod.data.data}
        accountNumber={accountNumber.data.data}
        transaction={transaction.data.data}
        transactionObj= {transaction}
        />
      }
      </>
  )
}

const TransactionComfirmationLayout = ({wallets,paymentMethodData,accountNumber,transaction,transactionObj}) => {


  //console.log('trans',transaction)

    //use balance context
    const {balances} = useWalletContext()

    //for websocket
    const { lastMessage, readyState } = useWebsocketContext()

    
    const wallet = wallets.find(w=>w.id===transaction.walletId);
    console.log('w',wallet)
    const pm = paymentMethodData.find(p => p.id === transaction.paymentMethod)

    const [note,setNote] = useState(transaction.note)
    //const [statusIcon,setStatusIcon] = useState(transaction.status)

    //console.log('transaction',transaction)
    //console.log('pm',pm)
    const [status,setStatus] = useState('')
    useEffect(()=>setStatus(transaction.status),[])
    //console.log(pm)

    //depot mobile
    let ussd
    let ussdAffiche
    //let opperator
    
    if(wallet.symbol === 'MGA'){


        if(pm.name === 'Mvola'){
          ussd = 'tel:%23 111*1*2*'+transaction.addressPayment?.address+'*'+transaction.balanceRequested+'*'+transaction.transactionId+'%23'
          ussdAffiche='#111*1*2*'+transaction.addressPayment?.address+'*'+transaction.balanceRequested+'*'+transaction.transactionId+'#'
        } else if(pm.name === 'Orange Money'){
          ussd = 'tel:%23 144*1*1*'+transaction.addressPayment?.address+'*'+transaction.addressPayment?.address+'*'+transaction.balanceRequested+'*2 %23'
          ussdAffiche='#144*1*1*'+transaction.addressPayment?.address+'*'+transaction.addressPayment?.address+'*'+transaction.balanceRequested+'*2#'
        } else {
          ussd = 'tel:%23 436*2*1*1*'+transaction.addressPayment?.address+'*'+transaction.addressPayment?.address+'*'+transaction.balanceRequested+''+transaction.transactionId+'%23'
          ussdAffiche='*436*2*1*1*'+transaction.addressPayment?.address+'*'+transaction.addressPayment?.address+'*'+transaction.balanceRequested+''+transaction.transactionId+'#'
        }
    }

    //for step
    const [step,setStep] = useState(0)
    const [failedStep, setFailedStep] = useState(0)
    const isStepFailed = (step) => {
      return step === failedStep;
    };
    
    //on load step
    useEffect(()=>{
      if(transaction.status === 'preProcessing'){
        setStep(0)
        setFailedStep(-1)
      } else if(transaction.status === 'preProcessingFailed'){
        setStep(0)
        setFailedStep(0)
      } else if(transaction.status === 'processing'){
        setStep(1)
        setFailedStep(-1)
      } else if(transaction.status === 'failed'){
        setStep(1)
        setFailedStep(1)
      }else if(transaction.status === 'success'){
        setStep(4)
        setFailedStep(-1)
      }
    },[])

    //update step according to websocket

    useEffect(()=>{
      console.log('ato miandry websocket',lastMessage)

      if(lastMessage){
        console.log("websocket last message",JSON.parse(lastMessage.data))

        //console.log("websocket last message",JSON.parse(lastMessage))
        const data=JSON.parse(lastMessage.data)
        if(data.action === 'COMFIRM_TRANSACTION_'+wallet.symbol){
          if(data.data.message === 'success'){
            balances.refetch()
            transactionObj.refetch()
            setStatus('success')
            setStep(4)
            setFailedStep(-1)
            setNote(data.data.note)
          } else if (data.data.message === 'failed'){
            transactionObj.refetch()
            setStatus('failed')
            setStep(1)
            setFailedStep(1)
            setNote(data.data.note)
          }
        }
      }

    },[lastMessage])

    //for dialogue
    const [dialogOpen,setDialogOpen] = useState(false)
    //for loading
    const [isLoading,setLoading] = useState(false)


  const [snackbar,setSnackbar] = useState(null)
  const [statusSnackbar,setStatusSnackbar] = useState(false)

  const axiosInstance = useAxios()

    //change etape of transaction
    const sendStatusProgression = (status,onSuccess,onFailure,failureMessage) => {


     const formData = new FormData();

      formData.append('status', status);

      axiosInstance.post('/api/transaction/'+transaction.transactionId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        console.log('Response:', response.data);
        onSuccess()
        setStatus(status)

      })
      .catch(error => {
        console.error('Error:', error);
        setSnackbar({children:'Erreur ', severity: 'error'})
        setStatusSnackbar(true)
        onFailure()
      });
    }

    const handleToProcessing = () => {
      
        setLoading(true)
        sendStatusProgression(
          'processing',
          ()=>{
            setLoading(false)
            setStep(1)
          } ,
          () => {
            setLoading(false)
          },
          "Erreur sur la comfirmation d'envoie de payment"
        )
    }

    //for canceling trasaction as theu didn't send money
    const handleFailureToSendMoney = () => {
        setDialogOpen(true)
        sendStatusProgression(
          'preProcessingFailed',
          ()=>{
            setDialogOpen(false)
            setFailedStep(0)
          } ,
          () => {
            setDialogOpen(false)
          },
          "Erreur "
        )
    }

    //for canceling trasaction as theu didn't send money
    const handleCancelToSendMoney = () => {
      setDialogOpen(true)
      sendStatusProgression(
        'preProcessingCanceled',
        ()=>{
          setDialogOpen(false)
          setFailedStep(-1)
        } ,
        () => {
          setDialogOpen(false)
        },
        "Erreur"
      )
  }


  useEffect(()=>{
    console.log('readystateAnatiniuseeffect',readyState)
    console.log('readystate open',ReadyState.OPEN)
    console.log('user',transaction.id)
    
  },[readyState])

    const [showState, setShowState] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowState(true)
        }, 30000)

        return () => clearTimeout(timer)
    }, [])

    return (
      <Container>

        <SnackBarStatus snackbar={snackbar} open={statusSnackbar} setOpen={setStatusSnackbar}/>
        <DialogPersistedComponent open={dialogOpen}>
            <CircularProgress/>
        </DialogPersistedComponent>
        <Grid container sx={{display:'flex', justifyContent:'center'}}>
            <Grid item xs={12} md={6}>
            <Paper sx={{ px:{xs:1,md:6}, pb:3, pt:2}}>
            
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Title title={'Depot'}/>
                <Typography variant='h6' sx={{fontWeight:'bold'}}>+ {numberFormatter.format(transaction.balanceRequested)}</Typography>
            </Box>


{/* Header of everything */}

                {
                  status !== 'preProcessing' &&
                <>
                 <Box sx={{display:'flex',justifyContent:'center'}}>
                  <Stepper activeStep={step} orientation="vertical">
                      {steps.map((step, index) => (
                      <Step key={step.label}>
                          <StepLabel error={isStepFailed(index)}>
                            {step.label}
                          </StepLabel>
                          <StepContent>
                            { isStepFailed(index) ?
                              <Typography sx={{color:'red'}}>{step.error}</Typography>
                            : <Typography>{step.description}</Typography>

                            }
                            
                          </StepContent>
                      </Step>
                      ))}
                  </Stepper>
                  </Box>
                  { status === 'preProcessingFailed' &&
                  <Box>
                  <Alert variant="filled" severity="error" sx={{mt:1}}>
                  La transaction a échoué puisque vous n'avez pas envoyé l'argent dans le délai de 15 minutes.
                  </Alert>
                  </Box>
                  }
                  { status === 'preProcessingCanceled' &&
                  <Box>
                  <CustomAlert variant="filled" severity={'error'} sx={{mt:1}} >
                    Vous avez annulé la transaction.
                  </CustomAlert>
                  </Box>
                  }
                  { status === 'failed' &&
                  <Box>
                  <Alert variant="filled" severity="error" sx={{mt:1}}>
                  La transaction a échoué .
                  </Alert>
                  </Box>
                  }
                  { status === 'success' &&
                  <Box>
                  <Alert variant="filled" severity="success" sx={{mt:1}}>
                  La transaction a été effectuée avec succès.
                  </Alert>

                  </Box>
                  }
                  { !!note && 
                    <Alert variant="filled" severity={status === 'success' ? 'success' : 'error'} sx={{mt:1}}>
                      {note}
                  </Alert>}
                  <Divider sx={{mt:2}}/>
                  </>

                }

{/* END Header of everything */}

{/* Preprocessing */}

          { status === 'preProcessing' &&
                <Box sx={{mt:2}}>
                <CountdownComponent transactionId={transaction.transactionId} onComplete={handleFailureToSendMoney}/>
                
                </Box>
                }

          {/* for national */}
          {/*category= 2 */}      
            { wallet.categoryId === 2 &&
                <>
                
                <Stack spacing={1} sx={{px:1,mt:2}}>
                  <Typography variant='h6' sx={{
                    fontWeight:'bold',
                  }}>Information de Paiement</Typography>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Numéro de l'expéditeur:</Typography>
                    <Typography sx={{fontWeight:'bold'}}>{transaction.accountNumber}</Typography>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Opérateur:</Typography>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                      <img src={`${backendUrl}image/logo/${pm.icon}`} alt={pm.name} width={30} height={30}/>
                      <Typography sx={{ml:1}}>{pm.name}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>

                    <Typography sx={{color:'grey'}}>numéro de versement:</Typography>
                    {/* eto logique */}
                    <Typography variant='h6'color='primary' fontWeight={'bold'}>
                      {
                        transaction.addressPayment ?
                        transaction.addressPayment.address
                        : <CircularProgress size={15}/> 
                      }
                    </Typography>
                  </Box>

                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Nom de versement:</Typography>
                    <Typography color={'primary'} fontWeight={'bold'}>
                    {
                        transaction.addressPayment ?
                        transaction.addressPayment.name
                        : <CircularProgress size={15}/> 
                      }
                    </Typography>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                  
                    <Typography sx={{color:'grey'}}>Total à payer:</Typography>
                    <Typography variant='h6'color='primary' fontWeight={'bold'}>{numberFormatter.format(transaction.balanceRequested)}  {wallet.symbol}</Typography>
                  </Box>

                  {/* haon hafa except preprocessing */}

                  { status !== 'preProcessing' &&
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>

                    <Typography sx={{color:'grey'}}>Status:</Typography>
                    <Box>
                      <StatusIcon severity={status === 'processing'? 'progressing' : status === 'success' ? 'success' : 'error'} size={30}/>
                    </Box>
                  </Box>
                  }
                  {/* amin'ny pre process ihan miseo */}
                  { status === 'preProcessing' &&
                  <>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                      <Typography sx={{color:'grey'}}>Code USSD:</Typography>
                      <Box>
                        
                      </Box>
                  </Box>
                  <Box>{
                    
                      transaction.addressPayment ?
                    <>
                    <Copy toCopy={ussdAffiche}/>
                    <Typography align='center'>ou</Typography>
                    <Box sx={{display:'flex', justifyContent:'center', width:'100%'}}>
                    <IconButton component='a' href={ussd} sx={{width:'fit-content', bgcolor:'green'}}> 
                      <MdOutlineCall />
                    </IconButton>
                    </Box>
                    </>
                    :
                    <Typography align='center'>
                        <CircularProgress size={25}/>
                    </Typography>
                    }
                  </Box>
                  
                  <Divider/>
                 {showState && <Box sx={{width:'100%'}}>
                  <Typography align={'center'}>Argent envoyer!! comfirmer l'envoi d'argent</Typography>

                  <Box sx={{display:'flex',mt:1 ,justifyContent:'center'}}>
                    <Button variant='outlined' color='error' onClick={handleCancelToSendMoney}>         
                      { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : ' Annuler'}
                    </Button>
                    <Button variant='contained' sx={{ml:1}} onClick={handleToProcessing}>         
                      { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : ' Comfirmer'}
                    </Button>
                  </Box>
                  </Box>
                  }
                  </>
                  }
                </Stack>
              </>
            }

            {/*End category= 2 */} 
            {/*End national*/} 



            {/*Crypto*/} 
            {/* category= 3 */}      
            { wallet.categoryId !== 2 &&
                <>
                
                <Stack spacing={1} sx={{px:1,mt:2}}>
                  <Typography variant='h6' sx={{
                    fontWeight:'bold',
                  }}>Information de Paiement</Typography>
                  
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Reseaux:</Typography>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                      <img src={`${backendUrl}image/logo/${pm.icon}`} alt={pm.name} width={30} height={30}/>
                      <Typography sx={{ml:1}}>{pm.name}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>

                    <Typography sx={{color:'grey'}}>Address:</Typography>
                    {/* eto logique */}
                    <Typography variant='h6'color='primary' fontWeight={'bold'}>
                      {
                        transaction.addressPayment ?

                        <CopyToClipboard toCopy={transaction.addressPayment.address} variant=''/>
                        
                        : <CircularProgress size={15}/> 
                      }
                    </Typography>
                  </Box>

                  
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                  
                    <Typography sx={{color:'grey'}}>Total à payer:</Typography>
                    <Typography variant='h6'color='primary' fontWeight={'bold'}>{numberFormatter.format(transaction.balanceRequested)}  {wallet.symbol}</Typography>
                  </Box>

                  {/* haon hafa except preprocessing */}

                  { status !== 'preProcessing' &&
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>

                    <Typography sx={{color:'grey'}}>Status:</Typography>
                    <Box>
                      <StatusIcon severity={status === 'processing'? 'progressing' : status === 'success' ? 'success' : 'error'} size={30}/>
                    </Box>
                  </Box>
                  }
                  {/* amin'ny pre process ihan miseo */}
                  { status === 'preProcessing' &&
                  <>
                 
                  
                  
                  <Divider/>
                 {showState && <Box sx={{width:'100%'}}>
                  <Typography align={'center'}>Argent envoyer!! comfirmer l'envoi d'argent</Typography>

                  <Box sx={{display:'flex',mt:1 ,justifyContent:'center'}}>
                    <Button variant='outlined' color='error' onClick={handleCancelToSendMoney}>         
                      { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : ' Annuler'}
                    </Button>
                    <Button variant='contained' sx={{ml:1}} onClick={handleToProcessing}>         
                      { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : ' Comfirmer'}
                    </Button>
                  </Box>
                  </Box>
                  }
                  </>
                  }
                </Stack>
              </>
            }
                
{/*END Preprocessing */}
{/* PreProccessing Failed */}

            </Paper>
            </Grid>
        </Grid>
      </Container>
    )
  }

/*

  export const TransactionComfirmation = () => {
    const steps = [
      { label: 'Dépôt soumis', subLabel: '08/10/2024, 18:12:34', completed: true },
      { label: 'En cours (24/25)', subLabel: 'Le dépôt sera crédité après 1 confirmation.', completed: true },
      { label: 'Effectué', subLabel: '', completed: false },
    ];
  
    return (
      <Box sx={{ width: '100%', padding: 3 }}>

      </Box>
    );
  };






  <Box sx={{display:'flex',justifyContent:'center'}}>
            <Stepper activeStep={0} orientation="vertical">
                {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel
                    optional={
                        index === steps.length - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                        ) : null
                    }
                    >
                    {step.label}
                    </StepLabel>
                    <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                        <Button
                        variant="contained"
                        sx={{ mt: 1, mr: 1 }}
                        >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button
                        disabled={index === 0}
                        sx={{ mt: 1, mr: 1 }}
                        >
                        Back
                        </Button>
                    </Box>
                    </StepContent>
                </Step>
                ))}
            </Stepper>
            </Box>
  */
  
