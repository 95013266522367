import { TaskAlt } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { MdContentCopy } from "react-icons/md";

export const Copy = ({toCopy}) => {
    const [isCopied, setCopied] = useState(false)

    const handleCopy = ()=>{ 
      navigator.clipboard.writeText(toCopy)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 5000);
    }

  return (
    <Box sx={{
       // border:'1px solid white',
        px:0.5,
        pb:1, 
        position:'relative'
        //borderRadius:1,
        //backgroundColor:'#00000022'
    }}>
        <Box sx={{
            display:'flex',
            justifyContent:'end',
            position:{xs:'absolute',md:'none'},
            top:{xs:-20,md:20},
            right:-10,
        }}>
            <IconButton variant='outlined' sx={{border:'1px solid white',p:0.5,borderRadius:1,backgroundColor:'#ffffff22'}}
                //color={isCopied?'greenSuccess': 'gray'} 
                onClick={handleCopy}
            >
                    {isCopied?<TaskAlt style={{fontSize:15}}/>:<MdContentCopy size={15}/>} 
            </IconButton>
        </Box>
        <Typography align='center' sx={{
          fontWeight:'bold',
          fontSize: {xs:15,md:16},
          wordBreak: 'break-word', // Break long words onto the next line
          overflowWrap: 'break-word', // Ensure wrapping works for long text
          whiteSpace: 'normal', // Allow wrapping instead of forcing a single line
          //for ... when overflowing
          //whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
          //overflow: 'hidden', // Hide overflowed text
          //textOverflow: 'ellipsis', // Replace overflowed text with '...'
          }}>
        {toCopy}
        </Typography>
        
    </Box>
  )
}
