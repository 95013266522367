import React, { useEffect, useState } from 'react'
import { TRXSCAN_API_KEY, TRC_USDT_ADDRESS, TRX_OWNER_ADDRESS, TRXSCAN_ENDPOINT } from '../../utils/constant';
import axios from 'axios';
import { Box, Paper, Typography, TextField, Button } from '@mui/material';
import { CopyToClipboard } from '../../component/ui';
import { TwoBarContentLoader } from '../../component/contentLoading';
import { IoSearch } from "react-icons/io5";

export const Tronscan = () => {
  const [address, setAddress] = useState(TRX_OWNER_ADDRESS)
  const [tokenTransaction, setTokenTransaction] = useState(null)
  const [usdtLoading, setUsdtLoading] = useState(false)
  const [error, setError] = useState(null)

  const chainData = {
    endpoint: TRXSCAN_ENDPOINT,
    usdtAddress: TRC_USDT_ADDRESS,
    apiKey: TRXSCAN_API_KEY,
    name: 'TRX',
    usdtName: 'TRC20 USDT'
  }

  const fetchTokenTransaction = async () => {
    try {
      setUsdtLoading(true)
      const response = await axios.get(`${chainData.endpoint}contract/events`, {
        params: {
          address: chainData.usdtAddress,
          only_to: true,
          only_confirmed: true,
          limit: 5,
          start: 0,
          contract_address: address,
          api_key: chainData.apiKey
        }
      })
      /setTokenTransaction(response.data.data.filter(tx => tx.type === 'Transfer'));
      //setTokenTransaction(response.data.data)
      setUsdtLoading(false);
    } catch (err) {
      setError(err.message);
      setUsdtLoading(false);
    }
  };

  const [transactions, setTransactions] = useState(null);
  const [txLoading, setTxLoading] = useState(false);
  const [txError, setTxError] = useState(null);

  const fetchTransactions = async () => {
    try {
      setTxLoading(true)
      const response = await axios.get(`${chainData.endpoint}transaction`, {
        params: {
          address: address,
          only_to: true,
          only_confirmed: true,
          limit: 5,
          start: 0,
          api_key: chainData.apiKey
        }
      });
      setTransactions(response.data.data);
      setTxLoading(false);
    } catch (err) {
      setTxError(err.message);
      setTxLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions()
    fetchTokenTransaction()
  }, [])

  const [addressField, setAddressField] = useState('');
  const [isAddressValid, setIsAddressValid] = useState(true)

  const handleButtonSearch = () => {
    // Check if address is a valid Tron address
    const isValidTronAddress = /^T[A-Za-z1-9]{33}$/.test(addressField);
    setIsAddressValid(isValidTronAddress);
    if (!isValidTronAddress) {
      setAddressField('')
      setIsAddressValid(false)
      return;
    }
    
    setIsAddressValid(true)
    
    if (addressField) {
      setTokenTransaction([])
      setTransactions([])
      setAddress(addressField);
      fetchTransactions();
      fetchTokenTransaction();
    }
  }

  return (
    <Box>
      <Box sx={{ display: 'flex',my:2}}>
        <TextField
          fullWidth
          placeholder="Search transactions..."
          sx={{ width: {xs:'90%',sm:300} }}
          value={addressField}
          onChange={(e) => setAddressField(e.target.value)}
          error={!isAddressValid}
        />
        <Button variant="contained" onClick={handleButtonSearch}>
          <IoSearch />
        </Button>
      </Box>

      <Typography>{chainData.name}</Typography>
      <TransactionHeader />
      {txLoading && <TwoBarContentLoader/>}
      {transactions && transactions.slice(0,5).map((transaction, index) => (
        <TransactionShow key={index} transaction={transaction} />
      ))}

      <Typography>{chainData.usdtName}</Typography>
      <TransactionHeader /> 
      {usdtLoading && <TwoBarContentLoader/>}
      {tokenTransaction && tokenTransaction.slice(0,5).map((transaction, index) => (
        <TransactionUsdtShow key={index} transaction={transaction} />
      ))}
    </Box>
  )
}

const TransactionUsdtShow = ({transaction}) => {
  const method = "Transfer";
  
  return (  
    <Paper sx={{
      display: {xs:'flex',sm:'flex'},
      width: '100%',
      padding: 1,
      my:1,
      alignItems: 'center',
      overflowX: {xs: 'scroll', md: 'visible'}
    }}>
      <Box sx={{ 
        width:{xs:'80px',md:'10%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography>{method}</Typography>
      </Box>

      <Box sx={{
        display:'flex', 
        width:{xs:'120px',md:'20%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{ml:0}}>{Number(transaction.value) / Math.pow(10, 6)}</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <CopyToClipboard toCopy={transaction.from} variant='ellipsis'/>
      </Box>

      <Box sx={{ 
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <CopyToClipboard toCopy={transaction.hash} variant='ellipsis'/>
      </Box>
    </Paper>
  )
}

const TransactionHeader = () => {
  return (
    <Box sx={{
      display: {xs:'flex',sm:'flex'},
      width: '100%',
      padding: 1,
      my:1,
      alignItems: 'center',
      justifyContent:'center',
      overflowX: {xs: 'scroll', md: 'visible'}
    }}>
      <Box sx={{
        width:{xs:'80px',md:'10%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{fontWeight:'bold'}}>Method</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'120px',md:'20%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{fontWeight:'bold'}}>Amount</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{fontWeight:'bold'}}>From</Typography>
      </Box>

      <Box sx={{
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{fontWeight:'bold'}}>Tx Hash</Typography>
      </Box>
    </Box>
  )
}

const TransactionShow = ({transaction}) => {
  return (
    <Paper sx={{
      display: {xs:'flex',sm:'flex'},
      width: '100%', 
      padding: 1,
      my:1,
      alignItems: 'center',
      overflowX: {xs: 'scroll', md: 'visible'}
    }}>
      <Box sx={{
        width:{xs:'80px',md:'10%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography>{transaction.trigger_info?.methodName || 'Transfer'}</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'120px',md:'20%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography>{Number(transaction.amount) / Math.pow(10, 6)}</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <CopyToClipboard toCopy={transaction.ownerAddress} variant='ellipsis'/>
      </Box>

      <Box sx={{
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <CopyToClipboard toCopy={transaction.hash} variant='ellipsis'/>
      </Box>
    </Paper>
  )
}
