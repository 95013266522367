import { Box, Button, CircularProgress, Container, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Title } from '../component/admin'
import { useQueries } from '@tanstack/react-query';
import { TwoBarContentLoader } from '../component/contentLoading';
import { axiosClient, backendUrl, useAxios } from '../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SnackBarStatus } from '../component';
import dayjs from 'dayjs';
import { useWebsocketContext } from '../context'
import { ReadyState } from 'react-use-websocket'

export const EditOrder = () => {


  const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id')
    const userId = searchParams.get('userId')
  
    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['order'+id],
          queryFn: () => axiosClient.get('order/'+id),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        {
            queryKey: ['user'+userId],
            queryFn: () => axiosClient.get('user/'+userId),
          },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    const [wallets,order,paymentMethod,user] = results
    
    
  return (
    <>
    {(
       order.isLoading 
      || order.data.data === undefined 
      || wallets.isLoading
      || wallets.data.data === undefined
      || paymentMethod.isLoading
      || paymentMethod.data.data === undefined
      || user.isLoading
      || user.data.data === undefined
      ) && <TwoBarContentLoader/>}
  
    {order.isSuccess 
      && order.data.data !== undefined  
      && wallets.isSuccess
      && wallets.data.data !== undefined
      && paymentMethod.isSuccess
      && paymentMethod.data.data !== undefined
      && user.isSuccess
      && user.data.data !== undefined
      && 
    <EditOrderComponent
    wallets={wallets.data.data} 
    order={order.data.data}
    paymentMethodData={paymentMethod.data.data}
    user={user.data.data}
    />
    }
    </>
  )
}
const EditOrderComponent = ({order, wallets, paymentMethodData,user}) => {


  const { readyState ,pushMessage } = useWebsocketContext()

    const wallet = wallets.find(w=>w.id===order.walletId)
    const pm = paymentMethodData.find(p=>p.id===order.paymentMethod)
    const [isLoading,setLoading] = useState(false)
    const navigate = useNavigate()

  
    const [snackbar,setSnackbar] = useState(null)
    const [statusSnackbar,setStatusSnackbar] = useState(false)
    const [note,setNote] = useState('')

    //boutton
    const [rejectLoading,setRejectLoading] = useState(false)
    const [comfirmLoading,setComfirmLoading] = useState(false)
  
    const axiosInstance = useAxios()
    console.log(order)
    const dataForm = new FormData()
     //alert('mety')

     const sendStatusProgression = (status,onSuccess,onFailure,failureMessage) => {


      const formData = new FormData();
 
       formData.append('status', status);
       formData.append('note',note)
      
 
       axiosInstance.post('/order/'+order.id, formData, {
         headers: {
           'Content-Type': 'multipart/form-data',
         },
       })
       .then(async response => {
         console.log('Response:', response.data);
         await onSuccess()
         navigate('/admin/order')
 
       })
       .catch(error => {
         console.error('Error:', error);
         setSnackbar({children:'Erreur ', severity: 'error'})
         setStatusSnackbar(true)
         onFailure()
       });
     }


     const handleComfirm = () => {

      console.log('readystate',readyState)
      
      setComfirmLoading(true)
      sendStatusProgression(
        'success',
        ()=>{
          if(readyState === ReadyState.OPEN){
            //userId,action,payload
            pushMessage(
              user.id,
              'COMFIRM_TRANSACTION_'+wallet.symbol,
              {
                message:'success',
                note: note,
              }
            )
          }
          setComfirmLoading(false)
         
        } ,
        () => {
          setComfirmLoading(false)
        },
        "Erreur sur la comfirmation d'envoie de payment"
      )
    }
    const handleFailure = () => {
      
      console.log('readystate',readyState)

      setRejectLoading(true)

      sendStatusProgression(
        'failed',
         () =>{
          console.log('mety test 1')
          console.log('readystate avant',"readyState="+readyState+" ReadyState.OPEN="+ReadyState.OPEN)
          if(readyState === ReadyState.OPEN){
            //userId,action,payload4

          console.log('mety test 2')
             pushMessage(
              user.id,
              'COMFIRMTRANSACTION',
              {
                message:'failed',
                note: note,
              }
            )
          }
          setRejectLoading(false)
         
        } ,
        () => {
          setRejectLoading(false)
        },
        "Erreur sur la comfirmation d'envoie de payment"
      )
  }

  useEffect(()=>{
    console.log('readystateAnatiniuseeffect',readyState)
    console.log('readystate open',ReadyState.OPEN)
    console.log('user',user.id)
    
  },[readyState])

    return(
    <>
     <Container>

        <SnackBarStatus snackbar={snackbar} open={statusSnackbar} setOpen={setStatusSnackbar}/>
        <Title title={"COMMANDE : "+order.type}/>
        <Grid container sx={{display:'flex', justifyContent:'center'}}>
            <Grid item xs={12} md={6}>
            <Paper sx={{ px:{xs:1,md:6}, pb:3, pt:2}}>


            <Stack spacing={1} sx={{px:1,mt:2}}>
                <Title title={order.type}/>
                  <Typography variant='h6' sx={{
                    fontWeight:'bold',
                  }}>Information de Paiement</Typography>

              
                 <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                  
                    <Typography sx={{color:'grey'}}>Solde demmande:</Typography>
                    <Typography variant='h6'color='primary' fontWeight={'bold'}>{order.balanceRequested}  {wallet.symbol}</Typography>
                  </Box>

                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                  
                    <Typography sx={{color:'grey'}}>Utilisateur:</Typography>
                    <Typography variant='h6'fontWeight={'bold'}>{user.username}</Typography>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Numéro de l'expéditeur:</Typography>
                    <Typography sx={{fontWeight:'bold'}}>{order.accountNumber}</Typography>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Portefeuille:</Typography>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                      <img src={`${backendUrl}image/logo/${wallet.icon}`} alt={wallet.name} width={30} height={30}/>
                      <Typography sx={{ml:1}}>{wallet.name}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Mode de paiment:</Typography>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                      <img src={`${backendUrl}image/logo/${pm.icon}`} alt={pm.name} width={30} height={30}/>
                      <Typography sx={{ml:1}}>{pm.name}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Reference:</Typography>
                    <Typography sx={{fontWeight:'bold'}}>{order.transactionId}</Typography>
                  </Box>
                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>

                    <Typography sx={{color:'grey'}}>numéro de versement:</Typography>
                    <Typography variant='h6'color='primary' fontWeight={'bold'}>0349856794 </Typography>
                  </Box>

                  <Box sx={{
                    display:'flex',
                    justifyContent:'space-between'
                  }}>
                    <Typography sx={{color:'grey'}}>Date:</Typography>
                    <Typography  fontWeight={'bold'}>{dayjs(order.date).format('YYYY-MM-DD HH:mm')}</Typography>
                  </Box>
                  
                  <Box>

                  <Typography sx={{}}>Note:</Typography>
                  <TextField
                    value={note}
                    onChange={(e)=>setNote(e.target.value)}
                    id="outlined-multiline-flexible"
                    multiline
                    maxRows={4}
                    fullWidth
                  />
                  </Box>
                  <Box sx={{display:'flex', justifyContent:'end'}}>
                    <Button onClick={()=>navigate('/admin/order')}>Annuler</Button>
                    <Button onClick={handleFailure} variant='outlined' color='error' sx={{ml:1}} >
                      
                    { rejectLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : ' Rejeter'}
                    </Button>
                    <Button onClick={handleComfirm} variant='contained' color='success' sx={{ml:1}}>
                    { comfirmLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : ' Comfirmer'}
                    </Button>
                  </Box>
                </Stack>

            </Paper>
            </Grid>
        </Grid>
    </Container>
    </>
   )
   
}