import React, { useEffect, useState } from 'react'
import { BSCSCAN_API_KEY, BEP20_USDT_ADDRESS, ETH_OWNER_ADDRESS, CORE_USDT_ADDRESS, ERC20_USDT_ADDRESS, CORESCAN_API_KEY, ETHERSCAN_API_KEY, CORESCAN_ENDPOINT  } from '../../utils/constant';
import axios from 'axios';
import { Box, Paper, Typography, TextField, Button } from '@mui/material';
import { CopyToClipboard } from '../../component/ui';
import { useSearchParams } from 'react-router-dom';
import { TwoBarContentLoader } from '../../component/contentLoading';
import { IoSearch } from "react-icons/io5";


export const Corescan = () => {

  const [address, setAddress] = useState(ETH_OWNER_ADDRESS)

  const [tokenTransaction, setTokenTransaction] = useState(null)
  const [usdtLoading, setUsdtLoading] = useState(false)
  const [error, setError] = useState(null)

  /*const [searchParams, setSearchParams] = useSearchParams();
  const [chain, setChain] = useState(searchParams.get('chain') || 'ETH')

  */
  const chainData = {
          endpoint: CORESCAN_ENDPOINT,
          usdtAddress: CORE_USDT_ADDRESS,
          apiKey: CORESCAN_API_KEY,
          name: 'core',
          usdtName: 'ERC20 USDT'
        }

  const fetchTokenTransaction = async () => {
    
    try {
      setUsdtLoading(true)
      //console.log("chain token", chain)
      const response = await axios.get(chainData.endpoint, {
        params: {
          module: 'account',
          action: 'tokentx',
          contractaddress: chainData.usdtAddress,
          address: address,
          startblock: '0',
          endblock: '999999999',
          sort: 'desc',
          page: 1,
          offset: 5,
          apikey: chainData.apiKey
        }
      })
      console.log("response usdt", chainData.name,chainData.apiKey, response.data)
      setTokenTransaction(response.data.result.filter(tx => tx.from.toLowerCase() !== address.toLowerCase()));
      setUsdtLoading(false);
    } catch (err) {
      setError(err.message);
      setUsdtLoading(false);
    }
  };

  const [transactions, setTransactions] = useState(null);
  const [txLoading, setTxLoading] = useState(false);
  const [txError, setTxError] = useState(null);

  const fetchTransactions = async () => {
    
    try {
      setTxLoading(true)
      //console.log("chain transaction", chain)
      const response = await axios.get(chainData.endpoint, {
        params: {
          module: 'account',
          action: 'txlist',
          address: address,
          startblock: '0',
          endblock: '99999999',
          sort: 'desc',
          page: 1,
          offset: 20,
          apikey: chainData.apiKey
        }
      });
      console.log("response", chainData.name,chainData.apiKey, response.data)
      setTransactions(response.data.result);
      setTransactions(response.data.result.filter(tx => tx.to.toLowerCase() === address.toLowerCase()));
      setTxLoading(false);
    } catch (err) {
      setTxError(err.message);
      setTxLoading(false);
    }
  };
  /*
  useEffect(() => {
    

    
    }, [])*/

  useEffect(() => {
      fetchTransactions()
      fetchTokenTransaction()
    
  }, [])

 

  /*useEffect(() => {
    

    fetchTokenTransaction();
    console.log("bscscan",tokenTransaction)
  }, []);
*/
  const [addressField, setAddressField] = useState('');
  const [isAddressValid, setIsAddressValid] = useState(true)

  const handleButtonSearch = () => {

    // Check if address is a valid Ethereum address
    const isValidEthereumAddress = /^0x[a-fA-F0-9]{40}$/.test(addressField);
    setIsAddressValid(isValidEthereumAddress);
    if (!isValidEthereumAddress) {
      setAddressField('')
      setIsAddressValid(false)
      return;
    }
    else {
      
      setIsAddressValid(true)
    }

    if (addressField) {
      setTokenTransaction([])
      setTransactions([])
      
      setAddress(addressField);
      fetchTransactions(chainData);
      fetchTokenTransaction(chainData);
    }
  }

  return (
    <Box>

      <Box sx={{ display: 'flex',my:2}}>
        <TextField
          fullWidth
          placeholder="Search transactions..."
          sx={{ width: {xs:'90%',sm:300} }}
          value={addressField}
          onChange={(e) => setAddressField(e.target.value)}
          error={!isAddressValid}
        />
        <Button variant="contained" onClick={handleButtonSearch}>
          <IoSearch />
        </Button>
      </Box>

      <Typography>{chainData.name}</Typography>
      <TransactionHeader />
      {txLoading && <TwoBarContentLoader/>}
      {transactions && transactions.slice(0,5).map((transaction, index) => (
        <TransactionShow key={index} transaction={transaction} />
      ))}


      <Typography>{chainData.usdtName}</Typography>
      <TransactionHeader /> 
      {usdtLoading && <TwoBarContentLoader/>}
      {tokenTransaction && tokenTransaction.slice(0,5).map((transaction, index) => (
        <TransactionUsdtShow key={index} transaction={transaction} />
      ))}


    </Box>
  )
}


const TransactionUsdtShow = ({transaction}) => {
  const method = "Transfer";
  
  return (  
    <Paper sx={{
      display: {xs:'flex',sm:'flex'},
      width: '100%',
      padding: 1,
      my:1,
      alignItems: 'center',
     // justifyContent:'center',
      overflowX: {xs: 'scroll', md: 'visible'}
    }}>
      <Box sx={{ 
        width:{xs:'80px',md:'10%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography>{method}</Typography>
      </Box>

      <Box sx={{
        display:'flex', 
        width:{xs:'120px',md:'20%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{ml:0}}>{Number(transaction.value) / Math.pow(10, 6)}</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <CopyToClipboard toCopy={transaction.from} variant='ellipsis'/>
      </Box>

      <Box sx={{ 
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <CopyToClipboard toCopy={transaction.hash} variant='ellipsis'/>
      </Box>

    </Paper>
)
}

const TransactionHeader = () => {
  return (
    <Box sx={{
      display: {xs:'flex',sm:'flex'},
      width: '100%',
      padding: 1,
      my:1,
      alignItems: 'center',
      justifyContent:'center',
      overflowX: {xs: 'scroll', md: 'visible'}
    }}>
      <Box sx={{
        width:{xs:'80px',md:'10%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{fontWeight:'bold'}}>Method</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'120px',md:'20%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{fontWeight:'bold'}}>Amount</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{fontWeight:'bold'}}>From</Typography>
      </Box>

      <Box sx={{
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography sx={{fontWeight:'bold'}}>Tx Hash</Typography>
      </Box>
    </Box>
  )
}

const TransactionShow = ({transaction}) => {
  return (
    <Paper sx={{
      display: {xs:'flex',sm:'flex'},
      width: '100%', 
      padding: 1,
      my:1,
      alignItems: 'center',
      overflowX: {xs: 'scroll', md: 'visible'}
    }}>
      <Box sx={{
        width:{xs:'80px',md:'10%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography>{transaction.functionName?.split('(')[0] || 'Transfer'}</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'120px',md:'20%'},
        px:{xs:0.5,md:1}
      }}>
        <Typography>{Number(transaction.value) / Math.pow(10, 18)}</Typography>
      </Box>

      <Box sx={{
        display:'flex',
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <CopyToClipboard toCopy={transaction.from} variant='ellipsis'/>
      </Box>

      <Box sx={{
        width:{xs:'200px',md:'35%'},
        px:{xs:0.5,md:1}
      }}>
        <CopyToClipboard toCopy={transaction.hash} variant='ellipsis'/>
      </Box>

    </Paper>
  )
}


