import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import { DollarIcon } from '../asset/icon'
import { backendUrl } from '../utils'

export const CryptoCotation = ({ img, name, symbol, price,lastChange, cours }) => {
  //console.log()
  return (
    <Paper sx={{
      display: {xs:'none',sm:'flex'},
      flexDirection: 'row',
      width: '100%',
      padding: 1,
      my:1,
  
      //borderBottom: '1px solid #ccc',
      alignItems: 'center'
    }}>
      <Box sx={{ width:'10%'}}>
      <img src={backendUrl+'image/logo/'+img} alt={symbol} width={40} height={40}/>

        {/*<img src={img} alt={`${name} icon`} width={40} height={40} />*/}
      </Box>
  
      <Box sx={{
        display:'flex',
        width:'35%',  
      }}>
        <Typography sx={{display:{xs:'none',sm:'flex'}}}>{name}</Typography> 
        <Typography sx={{ml:2,color:'grey'}}>{symbol}</Typography>
      </Box>
  
      <Box sx= {{ width:'25%', display:'flex',alignItems:'center' }}>
        <Typography>{price}</Typography>
        <DollarIcon width={12} height={12}/>
        <Typography sx={{fontStyle:'italic',ml:1,color: lastChange >= 0 ? 'green' : 'red',fontSize:12}}>{lastChange}%</Typography>
      </Box>
      <Typography sx= {{ width:'25%'}}>{cours}</Typography>
      <Box sx= {{ width:'25%', display:'flex',justifyContent:'center' }}>
        <Button variant='contained'>Trade</Button>
      </Box>
    </Paper>
  )
}
export const CryptoCotationHeader = () => (
    <Box sx={{
      display: {xs:'none',sm:'flex'},
      flexDirection: 'row',
      width: '100%',
      padding: 1,
      //backgroundColor: '#f0f0f0'
    }}>
      <Typography sx= {{ width:'25%'}}>Nom</Typography>
      <Box sx= {{ width:'25%', display:'flex', justifyContent:'end'}}> <Typography sx={{mr:2, fontStyle:'italic', color:'grey'}}>Prix: </Typography></Box>
      <Typography sx= {{ width:'25%', }}>USDT</Typography>
      <Typography sx= {{ width:'25%'}}>Ariary</Typography>
      <Box sx= {{ width:'25%', }}></Box>
    </Box>
  )

  export const CryptoCotationMobile = ({ img, symbol, price,lastChange, cours }) => {


    //console.log()
    return (
      <Paper sx={{
        display: {xs:'flex',sm:'none'},
        flexDirection: 'row',
        width: '100%',
        padding: 1,
        my:1,
    
        //borderBottom: '1px solid #ccc',
        alignItems: 'center',
        justifyContent:'center'
      }}>
        <Box sx={{ width:'10%'}}>
          <img src={backendUrl+'image/logo/'+img} alt={symbol} width={40} height={40}/>
          {/*<img src={img} alt={`${name} icon`} width={40} height={40} />*/}
        </Box>
    
        <Box sx={{
          display:'flex',
          width:'25%',  
        }}>
          <Typography sx={{ml:2}}>{symbol}</Typography>
        </Box>

        <Box sx= {{ width:'35%'}}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography >{price}</Typography>
            <DollarIcon width={12} height={12}/>
            <Typography sx={{fontStyle:'italic',ml:1,color: lastChange >= 0 ? 'green' : 'red',fontSize:12}}>{lastChange}%</Typography>
          </Box>
          <Typography sx={{fontStyle:'italic',ml:1,color:'grey',fontSize:12}}>{cours} MGA</Typography>
        </Box>
        <Box sx= {{ width:'25%', display:'flex',justifyContent:'center' }}>
          <Button variant='contained'>Trade</Button>
        </Box>
      </Paper>
    )
  }
  export const CryptoCotationHeaderMobile = () => (
      <Box sx={{
        display: {xs:'flex',sm:'none'},
        flexDirection: 'row',
        width: '100%',
        padding: 1,
        justifyContent:'center'
        //backgroundColor: '#f0f0f0'
      }}>
        <Typography sx= {{ width:'25%'}}>Nom</Typography>
        <Box sx= {{ width:'10%', display:'flex', justifyContent:'end'}}></Box>
        <Typography sx= {{ width:'35%', }}>Prix</Typography>
        <Box sx= {{ width:'25%', }}></Box>
      </Box>
    )