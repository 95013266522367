export const backendUrl = "https://test.bitjoycoin.com:83/"//"https://127.0.0.1:8000/"//"https://test.bitjoycoin.com:83/"//"https://127.0.0.1:8000/"//"https://test.bitjoycoin.com:83/"//"https://127.0.0.1:8000/"//"https://test.bitjoycoin.com:83/"//"https://127.0.0.1:8000/"//"https://test.bitjoycoin.com:83/"//"https://127.0.0.1:8000/"//"https://test.bitjoycoin.com:83/"//"https://127.0.0.1:8000/"//"https://test.bitjoycoin.com:83/"//"https://127.0.0.1:8000/"//
export const socketUrl = "wss://test.bitjoycoin.com:8080"//"ws://localhost:3001"//"wss://test.bitjoycoin.com:8080"//"ws://localhost:3001"//"ws://localhost:3001"//"wss://test.bitjoycoin.com:8080"//"wss://localhost:3001"////"ws://localhost:3001"//"ws://localhost:3001"//ws://test.bitjoycoin.com:3001

// ETH
export const ETHERSCAN_API_KEY = "1S9D5742BGNPWQ1CBWB7832F3YPRSEE331"
export const ETHERSCAN_ENDPOINT = "https://api.etherscan.io/api"
export const ERC20_USDT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7"
export const ETH_OWNER_ADDRESS = "0x0F1d1B706f2e6008EE4E967dB1887Cc5B80b4270"

// BSC
export const BSCSCAN_API_KEY = "FM9FGFNYRK6S1QV4FDM8QUQTFPAYQRNFUI"
export const BSCSCAN_ENDPOINT = "https://api.bscscan.com/api"
export const BEP20_USDT_ADDRESS = "0x55d398326f99059ff775485246999027b3197955"

// CORE
export const CORESCAN_API_KEY = "2d987a3f9bb54e1d89bb318a798d1793"
export const CORESCAN_ENDPOINT = "https://openapi.coredao.org/api?"
export const CORE_USDT_ADDRESS = "0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1"

// TRX
export const TRXSCAN_API_KEY = "83521810-224f-464c-835a-b1e8cee28f84"
export const TRXSCAN_ENDPOINT = "https://apilist.tronscanapi.com/api/"//"https://api.trongrid.io/api"
export const TRC_USDT_ADDRESS = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
export const TRX_OWNER_ADDRESS = "TGuY5t4d9AHgmterfGkUzBVJxDRXfejiAr"

export const pages = [
    {title:'Home',link:'/', offset: 0},
    {title:'Cotation',link:"/cotation", offset: -80},
    {title:'Trade',link:"/trade", offset: -80},
    {title:'Extra',link:"/extra", offset: -80},
    {title:'Portefeuille',link:"/wallet", offset: -80},
]
