import { Box, Button, CircularProgress, Container, Divider, FormControl, IconButton, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { axiosClient, numberFormatter, roundNumber, useAxios } from '../utils'
import { useQueries } from '@tanstack/react-query';
import { TwoBarContentLoader } from '../component/contentLoading';
import { BottomDrawer } from '../component/menu';
import { useSearchParams } from 'react-router-dom';
import { IoMdArrowDropdown } from "react-icons/io";
import { FaStar } from "react-icons/fa6"
import { CiStar } from "react-icons/ci"

import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormInput } from '../component/form';
import { priceService } from '../utils/service';
import { useStateContext, useWalletContext } from '../context';
import { SnackBarStatus } from '../component';


export const Trade = () => {
  
  const axiosInstance = useAxios()


  const queries = [
    {
      queryKey: ['api/trade'],
      queryFn: () =>   axiosInstance.get('/api/trade'),
      //axiosClient.get('trade'),
    },
    {
      queryKey: ['priceTrade'],
      queryFn: () => axiosClient.get('priceTrade'),
    }
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [trade,priceTrade] = results

  const {wallets,balances} = useWalletContext()

  return (
    <>
    {( wallets.isLoading
      || wallets.data.data === undefined
      || trade.isLoading
      || trade.data.data === undefined
      || priceTrade.isLoading
      || priceTrade.data.data === undefined
      || balances.isLoading
      || balances.data.data === undefined
      ) && <TwoBarContentLoader/>}

    { wallets.isSuccess
      && wallets.data.data !== undefined
      && trade.isSuccess
      && trade.data.data !== undefined
      && priceTrade.isSuccess
      && priceTrade.data.data !== undefined
      && balances.isSuccess
      && balances.data.data !== undefined
      && 
      <TradeLayout wallets={wallets.data.data} trade={trade.data.data} tradeObj = {trade} priceTrade={priceTrade.data.data} balances={balances.data.data} balancesObj={balances}/> 
    }
    </>
  )
}

const TradeLayout = ({wallets,trade, tradeObj, priceTrade , balances, balancesObj}) => {

  const axiosInstance = useAxios()

  const {price} = useStateContext()

  const [isLoading, setIsLoading]= useState(false)
  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)

  //console.log(balances)
  //for bottom drawer
  const [state, setState] = useState(false)


  //getCurrent crypto
  const getCurrentCryptoT= (tradeId) => trade
  .map((item) => {
    
    const wallet = item.compatibleWallet.find((wallet) => wallet.tradeId == tradeId);
    if (wallet) {
      return {
        id: item.id,
        name: item.name,
        symbol: item.symbol,
        icon: item.icon,
        compatibleWallet: wallet,
      };
    }
    return null;
  })
  .find((item) => item !== null);

  //get if there is on local storage
  const storedTradeData = JSON.parse(localStorage.getItem('lastTradeData'))

  let defaultTradeId
  let defaultType

  if(storedTradeData && storedTradeData.vr){
    //console.log('tr',storedTradeData.vr)
    const t = getCurrentCryptoT(storedTradeData.vr)

    if(t){
      defaultTradeId = storedTradeData.vr //tradeId
    } else {
      defaultTradeId = trade[0].compatibleWallet[0].tradeId //tradeId
    }
    defaultType = storedTradeData.tr //type 
  }
  else {
    defaultTradeId = trade[0].compatibleWallet[0].tradeId //tradeId
    defaultType = 'buy' //tradeId
  }

  //function to save on storage
  const saveLocal = (vr,tr) => {
    localStorage.setItem(
      'lastTradeData',
      JSON.stringify(
        {
          vr: vr,
          tr:tr
        }
      )
    )
  }

  const DEFAULTCRYPTO = defaultTradeId
  const DEFAULTTYPE= defaultType


  const [searchParams, setSearchParams] = useSearchParams();
  

  let id = searchParams.get('vr')||DEFAULTCRYPTO
  let type = searchParams.get('tr') ||DEFAULTTYPE

  const [crypto, setCrypto] = useState(id)
  const [typeTransac, setTypeTransac] = useState(type)
  
  const changeId = (newId) => {
    setSearchParams({ vr: newId,tr:typeTransac }); // Update the 'id' parameter in the URL
  }

  useEffect(()=>{
    setSearchParams({ vr:crypto,tr:typeTransac });
  },[typeTransac])

  useEffect(()=>{
  if (id !== null ){
    setCrypto(id)
  } else {
    setCrypto(DEFAULTCRYPTO)
  }

},[])


  const getCurrentCrypto= () => trade
  .map((item) => {
    
    const wallet = item.compatibleWallet.find((wallet) => wallet.tradeId == crypto);
    if (wallet) {
      return {
        id: item.id,
        name: item.name,
        symbol: item.symbol,
        icon: item.icon,
        round: item.round,
        roundTrade: item.roundTrade,
        compatibleWallet: wallet,
      };
    }
    return null;
  })
  .find((item) => item !== null);


  const [currentTrade, setCurrentTrade] = useState({})
  const [currentPriceTrade,setCurrentPriceTrade] = useState({})

  //default crypto 
  useEffect(()=>{
    const res = getCurrentCrypto()
    //console.log('mety', res)
    setCurrentTrade(res)
  },[])

  useEffect(()=>{
    changeId(crypto)
    const result = getCurrentCrypto()
    setCurrentTrade(result)
    //console.log('resu',result)
  },[crypto])

  //for any change of 
  useEffect(()=>{
    if(Object.keys(currentTrade).length>0 && Object.keys(price).length>0){
      //console.log(priceService(currentTrade.symbol,currentTrade.compatibleWallet.symbol,price,priceTrade))
      setCurrentPriceTrade(priceService(currentTrade.symbol,currentTrade.compatibleWallet.symbol,price,priceTrade))
    }
  },[currentTrade,price])

  //for balnce based on based crypto
  const [currentBalance,setCurrentBalance] = useState(0)
  useEffect(()=>{

    if(Object.keys(currentTrade).length !== 0){
      if(typeTransac==='buy'){
        const b = balances.find((w)=>w.walletId===currentTrade.id)
        setCurrentBalance(b.balance)
      } else {
        const b = balances.find((w)=>w.walletId===currentTrade.compatibleWallet.id)
        setCurrentBalance(b.balance)
      }
    }
  },[currentTrade,typeTransac,balances,balancesObj])
  //method to set favorite
  const setFavoriteTrade = () => {
    axiosInstance.post('/api/tradeFavorite/'+currentTrade.compatibleWallet.tradeId)
    .then((resp)=>{tradeObj.refetch()})
    .catch((e)=>{})
  }

  //prix
  const [currentPrice,setPrice] = useState(0)

  useEffect(()=>{
    //console.log('dghgj',currentTrade)
    if(Object.keys(currentPriceTrade).length !== 0){

      if(typeTransac === 'buy'){
        setPrice(currentPriceTrade.buyPrice)
      }
      else{
        setPrice(currentPriceTrade.sellPrice)
      }

      setAmount('')
      setPairAmount('')

    }
  },[typeTransac,currentPriceTrade])

  const dataForm = new FormData()

    const validationSchema = Yup.object().shape({
      amount : Yup.number()
          .typeError("Veuillez entrer un chiffre")
          .required("Veillez ajouter un nombre")
          .max(typeTransac === 'buy' ?  currentBalance*currentPrice : currentBalance,"Veuillez a ne pas depasser votre solde"),
      pairAmount : Yup.number()
          .typeError("Veuillez entrer un chiffre")
          .required("Veillez ajouter un nombre")
          .max(typeTransac === 'buy' ?  currentBalance: currentBalance*currentPrice,"Veuillez a ne pas depasser votre solde"),
    })
  
    const methods = useForm({
        resolver:yupResolver(validationSchema),
        defaultValues: {
            amount: '',
            pairAmount: '',
          },
      })
  
      
    const {handleSubmit,setValue,formState: { errors }} = methods
  
    //const amountWatch = watch('amount')
    //const pairAmountWatch = watch('pairAmount')

    const [pairAmount,setPairAmount]=useState('')
    const [amount,setAmount] = useState('')

    useEffect(() => {
      if (amount) {
        const value = amount * currentPrice
        //setAmount(amountWatch)
        if(value !== 0){
          setPairAmount(value);
        } else {
          setPairAmount('');
        }
        setValue('amount', value)
        setValue('pairAmount', value)
      }
    }, [setValue,currentPrice]);

    //for onChange
    const handleAmountValueChange = (e) =>{
      setAmount(e.target.value)
      setValue('amount', e.target.value)

      const value = e.target.value * currentPrice//roundNumber(e.target.value * currentPrice,currentTrade.compatibleWallet.roundTrade)
      //setAmount(amountWatch)

      if(value !== 0){
        setPairAmount(roundNumber(value,currentTrade.compatibleWallet.roundTrade));
        setValue('pairAmount', value);

      } else {
        setPairAmount('');
        setValue('pairAmount', '');
      }
      setValue('pairAmount', value);

    }

    const handlePairAmountValueChange = (e) =>{

      setPairAmount(e.target.value)
      setValue('pairAmount', e.target.value)
      
      const value = e.target.value / currentPrice //roundNumber(e.target.value / currentPrice,currentTrade.compatibleWallet.round)

      //setAmount(amountWatch)
      if(value !== 0){
        setAmount(roundNumber(value,currentTrade.compatibleWallet.round));
        setValue('amount', value);
      } else {
        setAmount('')
        setValue('amount','')

      }
      setValue('amount', value);

    }

      const onSubmit = async (data) => {
       setIsLoading(true)
       console.log(data)
        
      dataForm.append('baseTradeId',currentTrade.compatibleWallet.tradeId)
      dataForm.append('amount',data.amount)
      dataForm.append('pairAmount',data.pairAmount)
      dataForm.append('type',typeTransac)
      dataForm.append('price',currentPrice)

      //alert('mety')
      await axiosInstance.post('/api/tradetransaction',dataForm,
        {
            headers : {
                'content-type' : 'multipart/form-data',
            },
          transformRequest: dataForm => dataForm,//to solve axios issue
        })
        .then(async resp => {


            await balancesObj.refetch()

            setAmount('')
            setPairAmount('')

            setSnackbar({children:'Trade reuissi', severity: 'success'})
            setIsLoading(false)
            setStatus(true)
            //navigate('/transac/comfirmation/'+resp.data.transactionId)
        })
        .catch(error => {
            setIsLoading(false)
            setSnackbar({children:'Erreur sur le trade', severity: 'error'})
            setStatus(true)
            //console.log(error.response.data.message)
            })
      }

      //save on local if crypto change or type transact
      useEffect(()=>{saveLocal(crypto,typeTransac)},[crypto,typeTransac])

      useEffect(()=>{console.log('ty',currentBalance,currentPrice )},[currentBalance,currentPrice])
  

return (
  <>
  <BottomDrawer trade={trade} priceTrade={priceTrade} tradeObj={tradeObj} setCrypto={setCrypto} state={state} setState={setState}/>
  <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>

  { Object.keys(currentTrade).length > 0 && <Container sx={{ display:'flex',justifyContent:'center'}}>
    
      <Paper sx={{width:{xs:'100%',md:500}, px:{xs:1,md:6}, pb:3, pt:2}}>

      <FormProvider {...methods}>
      <form>
          <Stack alignItems={'center'} spacing={1} sx={{}}>
            {/* head of trade */}
            <Box sx={{ width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Box 
                onClick={()=>{setState(true)}}
                sx={{
                  width:'fit-content', 
                  display:'flex',
                  alignItems:'center', 
                  p:1, 
                  mb:1, 
                  borderRadius:1,
                  '&:hover':{
                    backgroundColor:'#ffffff11',
                    cursor:'pointer' 
                  } }}>
                    <Box sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                        
                        <Typography sx={{ml:0.5, fontSize:21}}>{currentTrade.compatibleWallet.symbol}</Typography>
                    </Box>
                    <Typography variant='h5' sx={{fontWeight:''}}>/</Typography>
                    <Box sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                       
                        <Typography sx={{ml:0.5, fontSize:21}}>{currentTrade.symbol}</Typography>
                    </Box>
                    <Box sx={{pr:0.5,pl:1,display:'flex',alignItems:'center'}}>
                      <IoMdArrowDropdown size={25}/>
                    </Box>
                </Box>
                {/* favorite buttton  */}
                <IconButton onClick={setFavoriteTrade}>
                  {currentTrade.compatibleWallet.favorite ? <FaStar size={23} color='yellow'/> : <CiStar size={25}/>}
                </IconButton>
                {/* end favorite buttton */}

            </Box>
            {/* END head of trade */}

              {/* bottom trade */}
              <Box sx={{display:'flex', width:'100%'}}>
                  <Button 
                    variant={typeTransac === 'buy' ? 'contained' : 'outlined'}
                    onClick={()=>setTypeTransac('buy')} 
                    sx={{width:'100%', mr:0.5}}>
                      Acheter
                  </Button>
                  <Button 
                    variant={typeTransac === 'buy' ? 'outlined' : 'contained'} 
                    color='danger'
                    onClick={()=>setTypeTransac('sell')} 
                    sx={{width:'100%', ml:0.5}}>
                      Vendre
                  </Button>
              </Box>
              {/* END bottom trade */}
              <Box sx={{
                bgcolor:'#292828',
                width:'100%',
                display:'flex',
                justifyContent:'center',
                p:1.5
              }}>
                {/* PRICE */}
                <Typography>
                  Prix ({currentTrade.symbol}) : 
                  {numberFormatter.format(
                    roundNumber( 
                      typeTransac === 'buy' ? currentPriceTrade.buyPrice:currentPriceTrade.sellPrice,
                      currentTrade.symbol === 'MGA' ? 0 : currentTrade.compatibleWallet.roundTrade
                    ))
                  }  
                </Typography>
              </Box>

              
                  <FormInput value={amount} onChange={handleAmountValueChange} sx={{mb:1}} name='amount' label={`Combient de ${currentTrade.compatibleWallet.symbol} voulez vous ${typeTransac === 'buy' ? 'Acheter':'vendre'}?`}  error={!!errors?.amount} helperText={errors?.amount?.message} fullWidth/>
                  <FormInput value={pairAmount} onChange={handlePairAmountValueChange} sx={{mb:1}}  name='pairAmount' label={`Total que vous allez payer en ${currentTrade.symbol}`} error={!!errors?.pairAmount} helperText={errors?.pairAmount?.message} fullWidth/>
                  <Box sx={{width:'100%', pt:1}}>
                    <Divider sx={{fontSize:56}}/>
                  </Box>
                  <Box sx={{display:'flex',justifyContent:'space-between',width:'100%', p:1}}>
                    <Typography>Solde disponible : </Typography>
                    <Typography>{numberFormatter.format(roundNumber( currentBalance ,currentTrade.symbol === 'MGA' ? 0 : typeTransac !== 'buy' ? currentTrade.compatibleWallet.round : currentTrade.round))} {typeTransac !== 'buy' ? currentTrade.compatibleWallet.symbol: currentTrade.symbol}</Typography>
                  </Box>
                  <Box sx={{display:'flex',justifyContent:'space-between',width:'100%', p:1, pt:0}}>
                    <Typography>{typeTransac === 'buy' ? 'Achat' :'Vemdre'} Max : </Typography>
                    <Typography>{numberFormatter.format(roundNumber( typeTransac === 'buy' ? currentBalance/currentPrice : currentBalance*currentPrice , typeTransac === 'buy' ? currentTrade.compatibleWallet.round : currentTrade.symbol === 'MGA' ? 0 : currentTrade.round))} {typeTransac === 'buy' ? currentTrade.compatibleWallet.symbol: currentTrade.symbol} </Typography>
                  </Box>

                  <Button  
                    variant='contained' 
                    sx={{width:'100%'}} 
                    color={typeTransac === 'buy' ? 'primary' : 'danger'} 
                    onClick={handleSubmit(onSubmit)}>
                    { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : typeTransac === 'buy' ? 'Acheter  ' + currentTrade.compatibleWallet.symbol : 'Vendre  ' + currentTrade.compatibleWallet.symbol}
                  </Button>
             
          </Stack>

          </form>
          </FormProvider>
      </Paper>
  </Container>}
  </>
)
}
