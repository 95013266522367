import { Box, Container, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { CryptoCotation, CryptoCotationHeader, CryptoCotationHeaderMobile, CryptoCotationMobile } from '../component'
import { axiosClient } from '../utils'
import { useStateContext } from '../context'
import { TwoBarContentLoader } from '../component/contentLoading'
import { useQueries } from '@tanstack/react-query'

export const Contation = () => {
  const queries = useMemo(() => [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
  ], [])

  const results = useQueries({ queries })

  const [wallets] = results

  return (
    <>
    {(wallets.isLoading || wallets.data?.data === undefined) && 
      <TwoBarContentLoader/>
    }

    {wallets.isSuccess && wallets.data?.data !== undefined && 
      <ContationLayout wallets={wallets.data.data}/> 
    }
    </>
  )
}

const ContationLayout = ({wallets}) => {
  const {priceW, readyState} = useStateContext()

  // Memoize filtered wallets to prevent unnecessary recalculations
  const walletsWithouMGA = useMemo(() => 
    wallets.filter(item => item.categoryId !== 2),
    [wallets]
  )

  // Memoize price lookup function and its results
  const getPriceValue = useMemo(() => {
    const priceMap = new Map(priceW.map(p => [p.symbol, p]))
    return (symbol) => priceMap.get(symbol) || {price: 0, lastChange: 0}
  }, [priceW])

  return (
    <Container sx={{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      width:{xs:'100%',md:700}
    }}>
      <Typography>{readyState}</Typography>
      
      <CryptoCotationHeader />
      <CryptoCotationHeaderMobile />

      {walletsWithouMGA.map((item, index) => {
        //const priceValue = priceW[item.symbol]
        const priceValue = getPriceValue(item.symbol)
        
        return (
          <Box key={item.symbol} sx={{width:'100%'}}>
            <CryptoCotation  
              img={item.icon} 
              name={item.name} 
              symbol={item.symbol} 
              //price={priceValue}  
              price={priceValue.price} 
              lastChange={priceValue.lastChange} 
              cours={item.cours} 
            />
            <CryptoCotationMobile 
              img={item.icon} 
              symbol={item.symbol} 
              //price={priceValue}  
              price={priceValue.price} 
              lastChange={priceValue.lastChange} 
              cours={item.cours} 
            />
          </Box>
        )
      })}
    </Container>
  )
}
