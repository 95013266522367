import { TaskAlt } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { MdContentCopy } from "react-icons/md";

export const CopyToClipboard = ({toCopy, variant}) => {
    const [isCopied, setCopied] = useState(false)

    const handleCopy = ()=>{ 
      navigator.clipboard.writeText(toCopy)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 5000);
    }

    const getTextStyle = () => {
      if (variant === 'ellipsis') {
        return {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%'
        }
      
      }
      
      return {
        wordBreak: 'break-word',
        overflowWrap: 'break-word', 
        whiteSpace: 'normal'
      }
    }

  return (
    <Box sx={{
        px:0.5,
        pb:1, 
        position:'relative',
        width: '100%'
    }}>
        <Box sx={{
            display:'flex',
            justifyContent:'end',
            position:{xs:'absolute',md:'none'},
            top:{xs:-5,md:-5},
            right:5,
        }}>
            <IconButton variant='outlined' sx={{border:'1px solid white',p:0.5,borderRadius:1,backgroundColor:'#ffffff22'}}
                onClick={handleCopy}
            >
                    {isCopied?<TaskAlt style={{fontSize:10}}/>:<MdContentCopy size={10}/>} 
            </IconButton>
        </Box>
        <Typography 
          align='center'
          sx={{
            fontWeight:'bold',
            fontSize: {xs:15,md:16},
            ...getTextStyle()
          }}
        >
          {toCopy}
        </Typography>
    </Box>
  )
}
