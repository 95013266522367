import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation} from 'react-router-dom'
import { useAuth } from './useAuth'
import { ButtonNavigation, Header } from '../component'
import { Box, Button } from '@mui/material'
import {  WalletContextProvider, WebsocketContextProvider } from '../context'
import backgroundImg from '../asset/image/Manavola.webp'

export const RequireAuth = () => {

    const auth = useAuth()
    const location = useLocation()

    const [toLogin,setToLogin] = useState(false)

    const [toVerificationMail,setToVerificationMail] = useState(false)

    useEffect(()=>{
        if(auth.isAuthenticated !== null && auth.isAuthenticated === false){
          setToLogin(true)
        }

        if(auth.user !== null && Object.keys(auth.user)<3){
          //setToLogin(true)
          alert('ati')
          auth.handleLogout()
        }

        //console.log("auth verified",auth.isEmailVerified)

        if(auth.isEmailVerified !== null && auth.isEmailVerified === false){
          setToVerificationMail(true)
        }

    },[auth.isAuthenticated,auth.isEmailVerified,auth])
  return (
    <>
    { auth.isAuthenticated && auth.isEmailVerified && auth.user !== null? 
            <>

        <WebsocketContextProvider userId={auth.user.id}> 
            
            <WalletContextProvider >
            
              <Box sx={{display: {xs:'none',md:'flex'}}}><Header/></Box>
                <Box sx={{
                  pt:{xs:2,md:9},
                  pb:{xs:9,md:3},
                  position:'relative'
                }}>
                  <Box component={'img'} src={backgroundImg} width={'65%'} sx={{position:'absolute', display:{xs:'flex',md:'none'}, zIndex:-5,right:0,top:40, opacity:'2%'}}/>
                  <Outlet/>
                </Box>
              <Box sx={{display: {xs:'flex',md:'none'}}}><ButtonNavigation/></Box>
           
            </WalletContextProvider>
            </WebsocketContextProvider>
            </>
        : <>
          <Button onClick={()=>auth.handleLogout()}></Button>
        </>
    }
    { toLogin &&
      <Navigate to='/login' state={{from: location}} replace /> 
    }
    { toVerificationMail &&
      <Navigate to='/emailVerification' state={{from: location}} replace /> 
    }
    </>
  )
}

///<Navigate to='/login' state={{from: location}} replace /> 