import { useQueries } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { axiosClient } from '../utils';

const cc= require('cryptocompare')
cc.setApiKey('b36bd91ed0a30ceaf6c090a5cf0cb352394f97ba99bc318e62e5896550046257')

const StateContext = createContext();

export const StateContextProvider = ({ children }) => {

  
 
  const [priceW, setPriceW] = useState([
    { symbol: 'USD', price: 1, lastChange: 0  },
    { symbol: 'USDT', price: 1, lastChange: 0 }
  ])

  const formatTickerData = (tickerData) => {
    try {
      if (!tickerData || !tickerData.instId) return null;

      const symbol = tickerData.instId.split('-')[0];
      const price = parseFloat(tickerData.last || 0);
      const open24h = parseFloat(tickerData.open24h || 0);
      const lastChange = open24h !== 0 ? 
        Math.round(((price - open24h) / open24h) * 100 * 1000) / 1000 : 
        0;

      return {
        symbol,
        price,
        lastChange
      };
    } catch (error) {
      console.error('Error formatting ticker data:', error);
      return null;
    }
  }


  const [price,setPrice] = useState([])


  const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    {
      queryKey: ['priceData'],
      queryFn: () => axiosClient.get('https://www.okx.com/api/v5/market/tickers?instType=SPOT'),
      refetchInterval: 15000, // Refetch every 15 seconds
    }
  ];

  const results = useQueries({ queries })

  const [wallets, priceData] = results


// Memoize active symbols
  const symbolActif = useMemo(() => {
    if (!wallets.data?.data) return [];
    const walletActif = wallets.data.data.filter(item => !['MGA', 'USD', 'USDT'].includes(item.symbol));
    return walletActif?.map(item => item.symbol) || [];
  }, [wallets.data?.data]);


  const symbolPrice = useMemo(() => {
    if (!priceData.data?.data || symbolActif.length === 0) return [];
    
    const symbolPrice = priceData.data.data.data.filter(item => 
      item.instId.endsWith('-USDT') && symbolActif.includes(item.instId.split('-')[0])
    );

    return symbolPrice.map(item => formatTickerData(item))
   
  }, [priceData.data?.data]); 

  useEffect(() => {
    if (symbolPrice.length > 0) {
      setPriceW(prevPriceW => {
        // Keep USD and USDT entries
        const baseEntries = prevPriceW.filter(item => ['USD', 'USDT'].includes(item.symbol));
        
        // Add new symbol prices
        const updatedPrices = [...baseEntries, ...symbolPrice];
        
        return updatedPrices;
      });
    }
  }, [symbolPrice]);

   
  useEffect(()=>{
    const fetchPrice = async () => {
      try {
        const walletActif = wallets.data.data.filter(item=>item.categoryId !== 2)
        const symbolActif = walletActif.map((item)=> item.symbol)
        
        const priceData = await cc.priceMulti(symbolActif,'USD')
        const formattedPrices = Object.entries(priceData).map(([key, value]) => ({
          [key]: value.USD
        }))
        setPrice(formattedPrices)
      } catch (err) {
        console.log("can't fetch core price")
      }
    }

    if(wallets.data?.data.length > 0){
      fetchPrice()
    }
  },[wallets.data?.data])


  return (
    <StateContext.Provider
      value={{
        price,
        priceW,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext);