import React , {useState} from 'react'
import { useWalletContext } from '../context'
import { useParams } from 'react-router-dom'
import { TwoBarContentLoader } from '../component/contentLoading'
import { Box, Button, CircularProgress, Divider, Grid, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { Title } from '../component/admin'
import { MdEdit } from "react-icons/md"
import { useQueries } from '@tanstack/react-query'
import { axiosClient, backendUrl } from '../utils'
import axios from 'axios'
import { SnackBarStatus } from '../component'



export const AddressPayment = () => {
  const {addressPayment} = useWalletContext()
  
 
  const queries = [
  {
    queryKey: ['paymentMethod'],
    queryFn: () => axiosClient.get('paymentMethod'),
  }
  ]

  const results = useQueries({ queries })

  const [ paymentMethods] = results
  return (
    <>
    {   (
        addressPayment.isLoading 
        || addressPayment.data.data === undefined 
        || paymentMethods.isLoading 
        || paymentMethods.data.data === undefined
        ) 
        && <TwoBarContentLoader/>
    }
    { 
        addressPayment.isSuccess 
        && addressPayment.data.data !== undefined 
        && paymentMethods.isSuccess 
        && paymentMethods.data.data !== undefined 
        && <AddressPaymentLayout addressPayment={addressPayment.data.data} addressPaymentObject={addressPayment} paymentMethods={paymentMethods.data.data}/>
    }
    </>
  )
}

const AddressPaymentLayout = ({addressPayment,addressPaymentObject,paymentMethods}) => {

    const {id} = useParams()

    console.log(addressPayment,id)
    const addressPaymentFilter = addressPayment.find(a => a.id === Number(id))
    const paymentMethodFiltered = paymentMethods.find(a => a.id === addressPaymentFilter.paymentMethodId)

    console.log(addressPaymentFilter,Number(id))

    const [isNameEdited,setNameEdited] = useState(false)
    const [isNameEditedLoading,setNameEditedLoading] = useState(false)
    const [nameEditedValue,setNameEditedValue] = useState(addressPaymentFilter.name)

    const [isAddressEdited,setAddressEdited] = useState(false)
    const [isAddressEditedLoading,setAddressEditedLoading] = useState(false)
    const [addressEditedValue,setAddressEditedValue] = useState(addressPaymentFilter.address)

    

    const [snackbar,setSnackbar] = useState(null)
    const [status,setStatus] = useState(false)
  
    const sendChange = (identifier,value,setEdited,setEditLoading) => {
     
      const dataForm = new FormData()

      console.log(identifier,value)

      dataForm.append('identifier',identifier)
      dataForm.append('value',value)
      
 

      axios.post(backendUrl+'editAddressPayment/'+id,dataForm,
        {
          headers : {
                'Content-Type' : 'application/json',
            },
          transformRequest: dataForm=> dataForm,//to solve axios issue
        })
      .then((resp)=>{
        setSnackbar({children:`Modification du ${identifier} avec success`, severity: 'success'})
        setEdited(false)
        setEditLoading(false)
        setStatus(true)
        addressPaymentObject.refetch()
      })
      .catch(e=>{
        setSnackbar({children:`Erreur sur la modification du ${identifier}`, severity: 'error'})
        setEditLoading(false)
        setStatus(true)
        console.log(e)
      })
    }

    const handleChangeWallet = (identifier) => {
        
      console.log(identifier)
        if (identifier === 'name'){
          console.log('anaty')
          setNameEditedLoading(true)

          //console.log(identifier,nameEditedValue,setNameEdited,setNameEditedLoading)
          sendChange(identifier,nameEditedValue,setNameEdited,setNameEditedLoading)
        }
    
      if (identifier === 'address'){
        setAddressEditedLoading(true)
        sendChange(identifier,addressEditedValue,setAddressEdited,setAddressEditedLoading)
      }
        
    
      }

  return (
    <Box>
        <Title title={"L'address de paiement pour "+paymentMethodFiltered.name}/>
        <Grid container>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          p:{xs:1,md:3}
        }}>
          <Stack spacing={1} sx={{px:2}}>
            {/* Name */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isNameEdited ?'white':'grey'}}>Nom</Typography>
                {!isNameEdited && <Typography>{addressPaymentFilter.name}</Typography>}
              </Box>
                {!isNameEdited && <IconButton onClick={()=>setNameEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isNameEdited &&
            <>
            <TextField value={nameEditedValue} onChange={(e)=> setNameEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setNameEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('name')} variant='contained' sx={{width:100}}>{isNameEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Name */}

            {/* Address */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isAddressEdited ?'white':'grey'}}>Adresse</Typography>
                {!isAddressEdited && <Typography>{addressPaymentFilter.address}</Typography>}
              </Box>
                {!isAddressEdited && <IconButton onClick={()=>setAddressEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isAddressEdited &&
            <>
            <TextField value={addressEditedValue} onChange={(e)=> setAddressEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setAddressEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('address')} variant='contained' sx={{width:100}}>{isAddressEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Address */}
            <Typography sx={{color: 'grey'}}>Busy Value</Typography>
            <Box sx={{display:'flex', flexWrap:'wrap'}}>
            {addressPaymentFilter.busyValue.map((item,index)=>{
                <Typography key={index} sx={{m:0.5}}>{item}</Typography>
            })}
            {addressPaymentFilter.busyValue.length === 0 && <Typography>No busy value</Typography>}
            </Box>
            {/* BUSY NUMBER */}
            <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
    </Box>
  )
}
